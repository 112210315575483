import React from 'react'

const Google = () => (
  <svg
    version="1.1"
    id="icon-google"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 25 25"
  >
    <path
      className="fill"
      d="M20,0H5C2.2,0,0,2.2,0,5v15c0,2.8,2.2,5,5,5h15c2.8,0,5-2.2,5-5V5C25,2.2,22.8,0,20,0z M6.7,12.9
	c0,0.5-0.4,1-1,1s-1-0.4-1-1v-1.1c0-0.5,0.4-1,1-1s1,0.4,1,1V12.9z M10.1,16.1L10.1,16.1c0,0.5-0.4,1-1,1s-1-0.4-1-1h0v-1.1
	c0-0.5,0.4-1,1-1s1,0.4,1,1V16.1z M10.1,12.1L10.1,12.1c0,0.5-0.4,1-1,1s-1-0.4-1-1h0V8.6c0-0.5,0.4-1,1-1s1,0.4,1,1V12.1z
	 M13.6,18.2v1.1h0c0,0.5-0.4,1-1,1s-1-0.4-1-1h0v-1.1h0c0,0,0,0,0,0c0-0.5,0.4-1,1-1S13.6,17.7,13.6,18.2
	C13.6,18.2,13.6,18.2,13.6,18.2L13.6,18.2z M13.6,15.2c0,0.5-0.4,1-1,1s-1-0.4-1-1V9.5c0-0.5,0.4-1,1-1s1,0.4,1,1V15.2z M13.6,6.6
	c0,0.5-0.4,1-1,1s-1-0.4-1-1V5.5c0-0.5,0.4-1,1-1s1,0.4,1,1V6.6z M17.1,12.7v3.5h0c0,0.5-0.4,1-1,1s-1-0.4-1-1h0v-3.5h0c0,0,0,0,0,0
	c0-0.5,0.4-1,1-1S17.1,12.1,17.1,12.7C17.1,12.7,17.1,12.7,17.1,12.7L17.1,12.7z M17.1,9.7c0,0.5-0.4,1-1,1s-1-0.4-1-1V8.6
	c0-0.5,0.4-1,1-1s1,0.4,1,1V9.7z M20.5,12.9L20.5,12.9c0,0.5-0.4,1-1,1s-1-0.4-1-1c0,0,0,0,0,0v-1.1c0,0,0,0,0,0c0-0.5,0.4-1,1-1
	s1,0.4,1,1h0V12.9z"
    />
  </svg>
)

export default Google
