import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useStaticQuery, graphql } from 'gatsby'
import { range } from 'ramda'
import Blog from 'images/icons/blog.svg'
import Book from 'images/icons/book.svg'
// import { FiList } from 'react-icons/fi'
import { BsCaretRightFill } from 'react-icons/bs'
import { setIsBlogView, setCurrentEpisodeId } from 'state/actions'
import { sizes } from 'styles'
import { PODCAST_PLAYER_HEIGHT } from 'src/consts'

const StyledMobileOptions = styled.div`
  @media ${sizes.tabletLandscape} {
    display: none;
  }
`

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: opacity 200ms;
  pointer-events: ${({ isVisible }) => (isVisible ? 'initial' : 'none')};
  background-color: ${({ theme }) => theme.color.secondary}96;
  backdrop-filter: blur(1px);
  z-index: 6001;

  ${({ isPodcastPlayerShowing }) =>
    isPodcastPlayerShowing &&
    css`
      bottom: ${PODCAST_PLAYER_HEIGHT}px;
    `}
`

const Toggle = styled.div`
  position: fixed;
  bottom: 20px;
  right: 22px;
  width: 55px;
  height: 55px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.color.tertiary};
  box-shadow: ${({ theme }) => theme.shadow};
  opacity: ${({ hideToggle }) => (hideToggle ? 0 : 1)};
  pointer-events: ${({ hideToggle }) => (hideToggle ? 'none' : 'initial')};
  z-index: 6002;

  ${({ isPodcastPlayerShowing }) =>
    isPodcastPlayerShowing &&
    css`
      bottom: ${PODCAST_PLAYER_HEIGHT + 20}px;
    `}

  > * {
    position: absolute;
    left: 14px;
    width: 26px;
    height: 4px;
    border-radius: 5px;
    background-color: ${({ theme }) => theme.color.white};
    transition: 200ms;
    transform: rotate(0deg);
  }

  > :first-child {
    top: 17px;
  }

  > :nth-child(2) {
    top: 26px;
  }

  > :last-child {
    top: 35px;
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      > :first-child {
        transform: rotate(45deg);
        top: 26px;
      }

      > :nth-child(2) {
        width: 0;
        opacity: 0;
      }

      > :last-child {
        transform: rotate(-45deg);
        top: 26px;
      }
    `};
`

const MenuItems = styled.div`
  position: absolute;
  bottom: 95px;
  right: 20px;
  width: 100%;

  ${range(0, 10).map(
    (i) => css`
      > :nth-child(${i + 1}) {
        bottom: ${75 * i}px;
      }
    `
  )}
`

const StyledMobileMenuItem = styled.div`
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const MobileMenuLabel = styled.div`
  padding: 10px;
  margin-right: 10px;
  border-radius: 5px;
  font-weight: bold;
  background-color: ${({ theme }) => theme.color.white};
  color: ${({ theme }) => theme.color.primary};
  box-shadow: ${({ theme }) => theme.shadow};
`

const MobileMenuIcon = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.color.accentSecondary};
  box-shadow: ${({ theme }) => theme.shadow};

  > * {
    width: 35px;
    height: 35px;
    fill: ${({ theme }) => theme.color.tertiary};
    color: ${({ theme }) => theme.color.tertiary};
  }
`

const chaptersQuery = graphql`
  query chaptersQuery {
    allContentfulChapter {
      edges {
        node {
          slug
          podcastEpisodes {
            id
          }
        }
      }
    }
  }
`

const MobileMenuItem = ({ label, icon, ...props }) => (
  <StyledMobileMenuItem {...props}>
    <MobileMenuLabel>{label}</MobileMenuLabel>
    <MobileMenuIcon>{icon}</MobileMenuIcon>
  </StyledMobileMenuItem>
)

const MobileOptions = ({
  pathname,
  hideToggle,
  // onTableOfContentsClick,
  onPlayPodcast,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const dispatch = useDispatch()
  const isPodcastPlayerShowing = useSelector(
    (state) => state.podcastPlayer.isShowing
  )

  const queryData = useStaticQuery(chaptersQuery)
  const chapters = queryData.allContentfulChapter.edges.map((edge) => edge.node)

  const isChapterPath = pathname.includes('chapter')
  const chapterPath = pathname.split('/chapter/')
  // show view toggles on topic pages, i.e. pathname is chapter/something/topicname
  const showViewToggles = isChapterPath && chapterPath[1].split('/').length > 1

  let episodeRelatedToChapter
  if (isChapterPath) {
    episodeRelatedToChapter = chapters.find(
      (chapter) => chapter.slug === pathname.split('/')[2]
    )?.podcastEpisodes?.[0]?.id
  }

  return (
    <StyledMobileOptions>
      <Overlay
        isVisible={isOpen}
        isPodcastPlayerShowing={isPodcastPlayerShowing}
      >
        <MenuItems>
          <MobileMenuItem
            onClick={() => {
              if (isChapterPath && episodeRelatedToChapter) {
                dispatch(setCurrentEpisodeId(episodeRelatedToChapter))
              }
              onPlayPodcast()
              setIsOpen(false)
            }}
            label={
              isChapterPath && episodeRelatedToChapter
                ? 'Play podcast for this chapter'
                : 'Play podcast'
            }
            icon={<BsCaretRightFill />}
          />
          {/* 
          <MobileMenuItem
            label="Table of contents"
            icon={<FiList />}
            onClick={() => {
              onTableOfContentsClick()
              setIsOpen(false)
            }}
          />
          */}
          {showViewToggles && (
            <>
              <MobileMenuItem
                label="Book view"
                icon={
                  <img
                    src={Book}
                    alt=""
                    onClick={() => {
                      dispatch(setIsBlogView(false))
                      setIsOpen(false)
                    }}
                  />
                }
              />
              <MobileMenuItem
                label="Blog view"
                icon={
                  <img
                    src={Blog}
                    alt=""
                    onClick={() => {
                      dispatch(setIsBlogView(true))
                      setIsOpen(false)
                    }}
                  />
                }
              />
            </>
          )}
        </MenuItems>
      </Overlay>
      <Toggle
        isPodcastPlayerShowing={isPodcastPlayerShowing}
        isOpen={isOpen}
        onClick={() => setIsOpen(!isOpen)}
        hideToggle={hideToggle}
      >
        <div />
        <div />
        <div />
      </Toggle>
    </StyledMobileOptions>
  )
}

export default MobileOptions
