import React from 'react'
import styled, { css } from 'styled-components'
import { useSelector } from 'react-redux'
import TransitionLink from 'components/TransitionLink'
import OutboundLink from 'components/OutboundLink'
import MadeBy from 'components/MadeBy'
import FooterImg from 'images/footer.png'
import FooterImgMobile from 'images/footer_mobile.png'
import FooterImgTablet from 'images/footer_tablet.png'
import Facebook from 'images/icons/facebook.svg'
import Twitter from 'images/icons/twitter.svg'
import Instagram from 'images/icons/instagram.svg'
import LinkedIn from 'images/icons/linkedin.svg'
import {
  FOOTER_HEIGHT,
  FOOTER_HEIGHT_MOBILE,
  FOOTER_HEIGHT_TABLET,
} from 'src/consts'
import { sizes } from 'styles'

const StyledFooter = styled.div`
  position: relative;
  height: ${FOOTER_HEIGHT_MOBILE}px;
  background-image: url(${FooterImgMobile});
  background-size: contain;
  background-position-y: bottom;
  background-repeat: repeat-x;
  ${({ isShowingPodcastPlayer }) =>
    isShowingPodcastPlayer &&
    css`
      border-bottom: 60px solid ${({ theme }) => theme.color.footer};
    `}

  @media ${sizes.tabletPortrait} {
    height: ${FOOTER_HEIGHT_TABLET}px;
    background-image: url(${FooterImgTablet});
    background-size: cover;
    background-position-y: top;
  }

  @media ${sizes.smallDesktop} {
    height: ${FOOTER_HEIGHT}px;
    background-image: url(${FooterImg});
    background-size: contain;
    background-position-y: bottom;
  }
`

const FooterContent = styled.div`
  position: absolute;
  bottom: 0;
  box-sizing: border-box;
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: ${({ theme }) => theme.color.white};

  @media ${sizes.tabletPortrait} {
    padding: 40px;
  }

  @media ${sizes.smallDesktop} {
    height: calc(${FOOTER_HEIGHT}px - 250px);
    flex-direction: row;
    align-items: center;
  }
`

const TitleAndLinks = styled.div`
  display: flex;
  flex-direction: column;

  @media ${sizes.smallDesktop} {
    flex-direction: row;
    align-items: center;
  }
`

const Title = styled.div`
  font-size: 28px;
  font-family: Times, serif;
  font-weight: bold;
`

const Links = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  > a {
    color: ${({ theme }) => theme.color.white};
    text-decoration: none;
    padding: 10px 0;

    :first-child {
      padding-top: 0;
    }
  }

  @media ${sizes.smallDesktop} {
    margin-top: 0;
    margin-left: 40px;
    flex-direction: row;
    align-items: center;

    > a {
      padding: 0 10px;
    }
  }

  @media ${sizes.desktop} {
    > a {
      padding: 0 20px;
      :last-child {
        display: none;
      }
    }
  }
`

const IconsAndMadeBy = styled.div`
  display: flex;
  flex-direction: column;

  @media ${sizes.smallDesktop} {
    flex-direction: row;
    align-items: center;
  }
`

const Icons = styled.div`
  margin-top: 20px;
  height: 30px;

  > a:not(:first-child) {
    margin-left: 10px;
  }

  > a > img {
    max-height: 30px;
  }

  @media ${sizes.smallDesktop} {
    margin-top: 0;
    display: flex;
    align-items: center;
    flex-direction: row;
  }
`

const StyledMadeBy = styled(MadeBy)`
  padding-bottom: 24px;
  padding-left: 40px;
  display: none;

  @media ${sizes.desktop} {
    display: block;
  }
`

const Copyright = styled.div`
  margin-top: 20px;
  color: #939393;

  @media ${sizes.tabletPortrait} {
    margin-top: 30px;
  }

  @media ${sizes.smallDesktop} {
    display: none;
  }
`

const Footer = () => {
  const isShowingPodcastPlayer = useSelector(
    (state) => state.podcastPlayer.isShowing
  )
  return (
    <StyledFooter isShowingPodcastPlayer={isShowingPodcastPlayer}>
      <FooterContent>
        <TitleAndLinks>
          <Title>Building Remotely</Title>
          <Links>
            <TransitionLink to="/about">About</TransitionLink>
            <OutboundLink
              href="https://safetywing.com/terms-and-conditions"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms & Conditions
            </OutboundLink>
            <OutboundLink
              href="https://safetywing.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              SafetyWing.com
            </OutboundLink>
          </Links>
        </TitleAndLinks>
        <IconsAndMadeBy>
          <Icons>
            <a
              href="https://www.facebook.com/safetywing/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Facebook} alt="facebook" />
            </a>
            <a
              href="https://twitter.com/safetywing"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Twitter} alt="twitter" />
            </a>
            <a
              href="https://www.instagram.com/safetywing"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Instagram} alt="instagram" />
            </a>
            <a
              href="https://www.linkedin.com/company/safetywing/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={LinkedIn} alt="linkedin" />
            </a>
          </Icons>
          <StyledMadeBy />
        </IconsAndMadeBy>
        <Copyright>
          {`© ${new Date().getFullYear()}, SafetyWing, Inc. All Rights Reserved.`}
        </Copyright>
      </FooterContent>
    </StyledFooter>
  )
}

export default Footer
