import React, { useState, useRef, useLayoutEffect } from 'react'
import { useDispatch } from 'react-redux'
import styled, { css } from 'styled-components'
import { BsCaretDownFill } from 'react-icons/bs'
import TransitionLink from 'components/TransitionLink'
import Subscribe from 'components/Subscribe'
import MadeBy from 'components/MadeBy'
import Button from 'components/Button'
import useWindowSize from 'hooks/useWindowSize'
import { setIsMobileProductNavOpen } from 'state/actions'
import { NAV_HEIGHT } from 'src/consts'
import { sizes, SCREEN_SIZES } from 'styles'

const StyledNavbar = styled.div`
  position: fixed;
  top: 0;
  box-sizing: border-box;
  width: 100%;
  height: ${NAV_HEIGHT}px;
  padding: 20px;
  padding-right: 28px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme, isOpen }) =>
    isOpen ? theme.color.backgroundsBorder : 'rgba(255, 255, 255, 0.9)'};
  transition: background-color 200ms, transform 200ms;
  z-index: 1;
`

const MobileNavToggle = styled.div`
  > svg {
    width: 20px;
    height: 20px;
    transition: transform 200ms;

    ${({ isOpen }) =>
      isOpen &&
      css`
        transform: rotate(180deg);
      `}

    @media ${sizes.smallDesktop} {
      display: none;
    }
  }
`

const MobileNav = styled.div`
  position: absolute;
  top: ${NAV_HEIGHT}px;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  max-height: calc(100vh - ${NAV_HEIGHT}px - 55px);
  padding: 20px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  background-color: ${({ theme }) => theme.color.backgroundsBorder};
  pointer-events: ${({ isOpen }) => (isOpen ? 'initial' : 'none')};
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transition: opacity 200ms;

  > a {
    padding: 15px 0;
    font-size: 26px;
    text-decoration: none;
    font-weight: bold;
    color: ${({ theme }) => theme.color.text};
  }

  > button {
    margin: 15px 0;
  }
`

const SubscribeText = styled.div`
  padding: 30px 0 20px 0;
`

const Title = styled(TransitionLink)`
  font-size: 26px;
  font-family: ${({ theme }) => theme.font.headings};
  font-weight: bold;
  color: ${({ theme }) => theme.color.text};
  text-decoration: none;
`

const StyledSubscribe = styled(Subscribe)`
  > * {
    max-width: 600px;
  }
  > form > button {
    align-self: flex-end;
  }

  position: relative;
  margin-top: 20px;

  ::before {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #c5c5c5;
  }
`

const StyledMadeBy = styled(MadeBy)`
  align-self: flex-end;
  padding-top: 30px;
`

const Links = styled.div`
  display: none;
  > * {
    margin-left: 20px;
    text-decoration: none;
    font-weight: bold;
    color: ${({ theme }) => theme.color.text};
  }

  @media ${sizes.smallDesktop} {
    display: block;
  }
`

const StyledLink = styled(TransitionLink).attrs({
  activeClassName: 'active',
})`
  &.active {
    color: ${({ theme }) => theme.color.tertiary};
  }
`

const RHButton = styled(Button)`
  color: ${({ theme }) => theme.color.white};
  width: max-content;
`

const Navbar = ({ isGlobalNavVisible, isProductNavOpen, ...restProps }) => {
  const dispatch = useDispatch()
  const [mobileNavIsOpen, setMobileNavIsOpen] = useState(false)
  const navbarRef = useRef()
  const { width } = useWindowSize()

  const toggleMobileNav = () => {
    if (width < SCREEN_SIZES.smallDesktop) {
      setMobileNavIsOpen(!mobileNavIsOpen)
      dispatch(setIsMobileProductNavOpen(!mobileNavIsOpen))
      isProductNavOpen && isProductNavOpen(!mobileNavIsOpen)
    }
  }

  const handleClickOutside = (e) => {
    if (!navbarRef.current || navbarRef.current.contains(e.target)) return
    setMobileNavIsOpen(false)
    dispatch(setIsMobileProductNavOpen(false))
    isProductNavOpen && isProductNavOpen(false)
  }

  useLayoutEffect(() => {
    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  const renderLinks = () => (
    <>
      <StyledLink to="/" onClick={toggleMobileNav}>
        Podcast
      </StyledLink>
      <StyledLink to="/subscribe/" onClick={toggleMobileNav} partiallyActive>
        Subscribe
      </StyledLink>
      <StyledLink to="/about/" onClick={toggleMobileNav} partiallyActive>
        About
      </StyledLink>
      <RHButton
        onClick={() =>
          window.open('https://safetywing.com/remote-health', '_blank')
        }
      >
        Remote Health
      </RHButton>
    </>
  )
  return (
    <StyledNavbar
      isGlobalNavVisible={isGlobalNavVisible}
      isOpen={mobileNavIsOpen}
      ref={navbarRef}
      {...restProps}
    >
      <Title to="/" onClick={() => setMobileNavIsOpen(false)}>
        Building Remotely
      </Title>
      <MobileNavToggle
        isOpen={mobileNavIsOpen}
        onClick={() => toggleMobileNav()}
      >
        <BsCaretDownFill />
      </MobileNavToggle>
      <MobileNav isOpen={mobileNavIsOpen}>
        {renderLinks()}
        <StyledSubscribe>
          <SubscribeText>
            Get future interviews, tools, and resources delivered straight to
            your inbox
          </SubscribeText>
        </StyledSubscribe>
        <StyledMadeBy dark />
      </MobileNav>
      <Links>{renderLinks()}</Links>
    </StyledNavbar>
  )
}

export default Navbar
