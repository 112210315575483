import React, { useRef, useCallback, useLayoutEffect, useState } from 'react'
import styled from 'styled-components'
import { clamp } from 'ramda'

const HALF_THUMB_WIDTH = 5

const StyledProgressBar = styled.div`
  position: relative;
  height: 15px;
  display: flex;
  align-items: center;
  cursor: pointer;

  > * {
    pointer-events: none;
  }
`

const Track = styled.div`
  width: 100%;
  height: 5px;
  border-radius: 2px;
  background-color: ${({ theme }) => theme.color.textSecondary};
  cursor: pointer;
`

const Thumb = styled.div`
  position: absolute;
  top: 50%;
  left: 0%;
  width: ${2 * HALF_THUMB_WIDTH}px;
  height: ${2 * HALF_THUMB_WIDTH}px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.color.accentSecondary};
  cursor: pointer;
  transition: transform 300ms linear;
`

const Progress = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  width: 0;
  height: 5px;
  border-radius: 2px;
  background-color: ${({ theme }) => theme.color.tertiary};
  cursor: pointer;
  transition: width 300ms linear;
`

const ProgressBar = ({
  duration,
  currentProgress,
  // Emitted when thumb moves
  // Update times with this event
  onThumbMove,
  // Emitted after thumb has been let go, on mouse up and touch up
  // At this point its safe to update audio.currentTime
  onUpdatePosition,
  className,
}) => {
  const [progressBarWidth, setProgressBarWidth] = useState(0)
  const [dragStartPosition, setDragStartPosition] = useState(-1)
  const ref = useRef(null)
  const thumbRef = useRef(null)
  const lastPercentageRef = useRef(0)
  const progress = currentProgress / duration

  const handleDrag = useCallback(
    (e) => {
      e.preventDefault()

      const el = ref && ref.current
      const thumbEl = thumbRef && thumbRef.current
      if (!thumbEl || !el) {
        return
      }

      const width = el.clientWidth

      const getPercentage = (e) => {
        const event = e.touches ? e.touches[0] : e

        const rect = el.getBoundingClientRect()
        const x = event.clientX - rect.left
        const percentage = clamp(0, 1, x / width)
        return percentage
      }

      const handleMove = (e) => {
        const percentage = getPercentage(e)
        lastPercentageRef.current = percentage

        // Override thumb position while dragging
        thumbEl.style.transition = 'none'
        thumbEl.style.transform = `translate3d(${
          percentage * progressBarWidth - HALF_THUMB_WIDTH
        }px, -50%, 0)`
        onThumbMove && onThumbMove(percentage)
      }

      const handleEnd = () => {
        // Reset thumb position when dragging ends
        thumbEl.style.transition = ''
        window.removeEventListener('mousemove', handleMove)
        window.removeEventListener('mouseup', handleEnd)
        window.removeEventListener('touchmove', handleMove)
        window.removeEventListener('touchend', handleEnd)
        onUpdatePosition && onUpdatePosition(lastPercentageRef.current)
        onThumbMove && onThumbMove()
        setDragStartPosition(-1)
      }

      // Handle mousedown / touchstart
      const percentage = getPercentage(e)
      setDragStartPosition(percentage * progressBarWidth)
      handleMove(e)
      window.addEventListener('mousemove', handleMove)
      window.addEventListener('mouseup', handleEnd)
      window.addEventListener('touchmove', handleMove)
      window.addEventListener('touchend', handleEnd)

      return () => {
        window.removeEventListener('mousemove', handleMove)
        window.removeEventListener('mouseup', handleEnd)
        window.removeEventListener('touchmove', handleMove)
        window.removeEventListener('touchend', handleEnd)
      }
    },
    [ref, lastPercentageRef, onUpdatePosition, onThumbMove, progressBarWidth]
  )

  useLayoutEffect(() => {
    if (!ref.current) return
    const setWidth = () => setProgressBarWidth(ref.current.clientWidth)
    setWidth()
    window.addEventListener('resize', setWidth)
    return () => {
      window.removeEventListener('resize', setWidth)
    }
  }, [ref.current])

  return (
    <StyledProgressBar
      ref={ref}
      className={className}
      onMouseDown={handleDrag}
      onTouchStart={handleDrag}
    >
      <Track />
      <Progress
        style={{
          width: `${progress * 100}%`,
        }}
      />
      {progressBarWidth > 0 && (
        <Thumb
          ref={thumbRef}
          style={
            dragStartPosition === -1
              ? {
                  transform: `translate3d(${
                    progress * progressBarWidth - HALF_THUMB_WIDTH
                  }px, -50%, 0)`,
                }
              : {
                  transform: `translate3d(${
                    dragStartPosition - HALF_THUMB_WIDTH
                  }px, -50%, 0)`,
                }
          }
        />
      )}
    </StyledProgressBar>
  )
}

export default ProgressBar
