import React, { StrictMode, useEffect, useState } from 'react'
import { ThemeProvider } from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import { useDispatch, useSelector } from 'react-redux'
import GlobalNavigation from 'components/GlobalNavigation'
import Footer from 'components/Footer'
import MobileOptions from 'components/MobileOptions'
import MobileChapterNavOverlay from 'components/MobileChapterNavOverlay'
import PodcastPlayer from 'components/PodcastPlayer'
import { setPodcastPlaylist, setIsShowing, setIsPlaying } from 'state/actions'
import { GlobalStyle, theme } from 'styles'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fal } from '@fortawesome/pro-light-svg-icons'

const podcastPlaylistQuery = graphql`
  query podcastPlaylistQuery {
    allContentfulPodcastEpisode(sort: { fields: episodeNumber }) {
      edges {
        node {
          id
          description {
            description
          }
          episodeNumber
          publishedOn
          title
          slug
          tags
          speakers {
            name
          }
          audioFile {
            file {
              url
            }
          }
          image {
            fluid(maxWidth: 300) {
              ...GatsbyContentfulFluid
            }
          }
          applePodcastsLink
          googlePodcastsLink
          spotifyLink
          iHeartRadioLink
        }
      }
    }
  }
`

const PageWrapper = ({ location, children }) => {
  const dispatch = useDispatch()
  const [showChapterNavigation, setShowChapterNavigation] = useState(false)
  const queryData = useStaticQuery(podcastPlaylistQuery)
  const isShowing = useSelector((state) => state.podcastPlayer.isShowing)

  library.add(fal)

  useEffect(() => {
    if (location.search.includes('referenceID')) {
      const params = new URL(location.href).searchParams
      localStorage.setItem('referenceID', params.get('referenceID'))
    }
  }, [])

  useEffect(() => {
    const podcastPlaylist = queryData.allContentfulPodcastEpisode.edges.map(
      (edge) => edge.node
    )
    dispatch(setPodcastPlaylist(podcastPlaylist))
  }, [queryData, dispatch])

  return (
    <ThemeProvider theme={theme}>
      <StrictMode>
        <GlobalStyle />
        <MobileChapterNavOverlay
          pathname={location.pathname}
          hide={!showChapterNavigation}
          onClose={() => setShowChapterNavigation(false)}
        />
        <GlobalNavigation pathname={location.pathname} />
        <MobileOptions
          pathname={location.pathname}
          hideToggle={showChapterNavigation}
          onTableOfContentsClick={() => setShowChapterNavigation(true)}
          onPlayPodcast={() => {
            dispatch(setIsShowing(true))
            dispatch(setIsPlaying(true))
          }}
        />
        {isShowing && <PodcastPlayer sticky />}
        {children}
        <Footer />
        <div id="modal-portal" />
      </StrictMode>
    </ThemeProvider>
  )
}

export default PageWrapper
