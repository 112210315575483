import React from 'react'
import styled, { css } from 'styled-components'
import HoverIcon from './HoverIcon'
import SharingLinks from './SharingLinks'
import Apple from './podcastIcons/Apple.js'
import Google from './podcastIcons/Google.js'
import Iheart from './podcastIcons/Iheart.js'
import Spotify from './podcastIcons/Spotify.js'

const PodcastLinksContainer = styled.div`
  position: relative;
`

const Icons = styled.div`
  display: flex;
  flex-direction: row;

  > * {
    margin: 0 10px 0 0;
  }

  ${({ vertical }) =>
    vertical &&
    css`
      flex-direction: column;
      > * {
        margin: 0;
      }
      > :not(:last-child) {
        margin-bottom: 10px;
      }
    `}
`

const PodcastLinks = ({
  variant,
  episodeNumber,
  applePodcastsLink,
  googlePodcastsLink,
  spotifyLink,
  iHeartRadioLink,
  vertical,
  showLabels,
}) => {
  if (variant === 'sharing') {
    return (
      <PodcastLinksContainer vertical={vertical}>
        <Icons vertical={vertical}>
          <SharingLinks episodeNumber={episodeNumber} />
        </Icons>
      </PodcastLinksContainer>
    )
  }
  if (
    !applePodcastsLink &&
    !googlePodcastsLink &&
    !spotifyLink &&
    !iHeartRadioLink
  )
    return null
  return (
    <PodcastLinksContainer vertical={vertical}>
      <Icons vertical={vertical}>
        {applePodcastsLink && (
          <HoverIcon
            showLabel={showLabels}
            labelOnLeft={vertical}
            hoverText="Listen on Apple Podcasts"
            to={applePodcastsLink}
          >
            <Apple />
          </HoverIcon>
        )}
        {googlePodcastsLink && (
          <HoverIcon
            showLabel={showLabels}
            labelOnLeft={vertical}
            hoverText="Listen on Google Podcasts"
            to={googlePodcastsLink}
          >
            <Google />
          </HoverIcon>
        )}
        {spotifyLink && (
          <HoverIcon
            showLabel={showLabels}
            labelOnLeft={vertical}
            hoverText="Listen on Spotify"
            to={spotifyLink}
          >
            <Spotify />
          </HoverIcon>
        )}
        {iHeartRadioLink && (
          <HoverIcon
            showLabel={showLabels}
            labelOnLeft={vertical}
            hoverText="Listen on iHeart Radio"
            to={iHeartRadioLink}
          >
            <Iheart />
          </HoverIcon>
        )}
      </Icons>
    </PodcastLinksContainer>
  )
}

export default PodcastLinks
