import React, { useState, useEffect } from 'react'

const OutboundLink = ({ children, href, ...props }) => {
  const [newHref, setNewHref] = useState(href)

  useEffect(() => {
    const referenceID = localStorage.getItem('referenceID')
    if (
      href.includes('safetywing.com') &&
      referenceID &&
      !href.includes('mailto')
    ) {
      const url = new URL(href)
      url.searchParams.set('referenceID', referenceID)
      setNewHref(url)
    }
  }, [href])

  return (
    <a href={newHref} {...props}>
      {children}
    </a>
  )
}

export default OutboundLink
