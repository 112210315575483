import { getBrowser } from 'src/helpers'

export const subscribe = async (email) => {
  try {
    return await fetch(process.env.GATSBY_API_URL + '/submit/email', {
      method: 'POST',
      headers: {
        secretKey: process.env.GATSBY_API_SECRET,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, source: 'BUILDING_REMOTELY' }),
    })
  } catch (e) {
    console.log(e)
  }
}

export const giveFeedback = async (message) => {
  const messageToLog = message.replace(/[\r\n]+/gm, ' ').trim()
  const isProd = process.env.GATSBY_ENV === 'production'
  if (!messageToLog.length) {
    return
  }
  try {
    return await fetch(process.env.GATSBY_API_URL + '/submit/log', {
      method: 'POST',
      headers: {
        secretKey: process.env.GATSBY_API_SECRET,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        message: messageToLog,
        type: isProd
          ? 'BUILDING_REMOTELY_FEEDBACK'
          : 'BUILDING_REMOTELY_FEEDBACK_STAGING',
        action: isProd
          ? 'BUILDING_REMOTELY_FEEDBACK'
          : 'BUILDING_REMOTELY_FEEDBACK_STAGING',
        level: 'INFO',
        browser: getBrowser(),
      }),
    })
  } catch (e) {
    console.log(e)
  }
}

export const pingZappier = async (firstName, lastName, email, paperName) => {
  if (process.env.GATSBY_ENV === 'production') {
    try {
      return await fetch(
        'https://hooks.zapier.com/hooks/catch/9582527/bedfebj',
        {
          method: 'POST',
          body: JSON.stringify({
            firstName,
            lastName,
            email,
            whitePaper: paperName,
          }),
        }
      )
    } catch (e) {
      console.log(e)
    }
  }
}
