import React, {
  useRef,
  useMemo,
  useLayoutEffect,
  useCallback,
  useEffect,
} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  setAudioIsLoading,
  setEpisodeDuration,
  setEpisodeCurrentProgress,
  setOverrideProgress,
  playNextEpisode,
} from 'state/actions'

const AudioController = () => {
  const lastEpisodeId = useRef()
  const audioRef = useRef()
  const playPromiseRef = useRef(null)
  const dispatch = useDispatch()
  const [
    podcastPlaylist,
    isPlaying,
    currentEpisodeId,
    overrideProgress,
    episodeCurrentProgresses,
    episodeDurations,
    volume,
  ] = useSelector((state) => [
    state.podcastPlaylist,
    state.podcastPlayer.isPlaying,
    state.podcastPlayer.currentEpisodeId,
    state.podcastPlayer.overrideProgress,
    state.episodeCurrentProgresses,
    state.episodeDurations,
    state.volume,
  ])

  const episode = useMemo(
    () => podcastPlaylist.find((x) => x.id === currentEpisodeId),
    [currentEpisodeId, podcastPlaylist]
  )

  const handleEnded = useCallback(() => {
    dispatch(playNextEpisode(currentEpisodeId))
  }, [dispatch, currentEpisodeId])

  useEffect(() => {
    dispatch(setAudioIsLoading(true))
  }, [dispatch, currentEpisodeId])

  useLayoutEffect(() => {
    audioRef.current.volume = volume
  }, [audioRef, volume, currentEpisodeId])

  // Handles play / pause
  useLayoutEffect(() => {
    // Audio controlling must be delayed a bit so audio element can keep up
    // Otherwise errors are thrown! 🧨
    setTimeout(async () => {
      const audio = audioRef?.current
      if (!audio) {
        return
      }

      if (!isPlaying) {
        if (playPromiseRef.current) {
          await playPromiseRef.current
        }
        audio.pause()
        return
      }

      // Start from scrubbed position if available
      const episodeProgress = episodeCurrentProgresses[currentEpisodeId] || 0
      if (
        lastEpisodeId.current !== currentEpisodeId &&
        episodeProgress &&
        episodeProgress !== episodeDurations[currentEpisodeId]
      ) {
        audio.currentTime = episodeProgress
      }
      lastEpisodeId.current = currentEpisodeId
      playPromiseRef.current = audioRef.current.play()
      return
    }, 150)
    // episodeCurrentProgresses and episodeDurations not included in deps array
    // because it's only needed when isPlaying has changed
    // and when isPlaying changes, episodeCurrentProgresses will
    // also have a fresh value. Including episodeCurrentProgresses
    // in the deps would cause an error when the playlist ends
    // eslint-disable-next-line
  }, [isPlaying, audioRef, lastEpisodeId, currentEpisodeId, playPromiseRef])

  // Handles current progress updates to state
  useLayoutEffect(() => {
    const audio = audioRef?.current
    if (!audio || !isPlaying) {
      return
    }
    const updateCurrentProgress = () => {
      if (!currentEpisodeId || !audio?.currentTime) {
        return
      }
      dispatch(setEpisodeCurrentProgress(currentEpisodeId, audio.currentTime))
    }
    audio.addEventListener('timeupdate', updateCurrentProgress)

    return () => {
      audio.removeEventListener('timeupdate', updateCurrentProgress)
    }
  }, [audioRef, dispatch, currentEpisodeId, isPlaying])

  // Handles scrubbing
  useLayoutEffect(() => {
    if (overrideProgress === -1) {
      return
    }
    const audio = audioRef?.current
    if (!audio) {
      return
    }
    audio.currentTime = overrideProgress
    dispatch(setOverrideProgress(-1))
    dispatch(setEpisodeCurrentProgress(currentEpisodeId, audio.currentTime))
  }, [audioRef, overrideProgress, currentEpisodeId, dispatch])

  return (
    <>
      <audio
        ref={audioRef}
        onCanPlayThrough={() => {
          dispatch(setAudioIsLoading(false))
        }}
        onLoadedMetadata={(event) => {
          const episodeDuration = event.target.duration
          dispatch(setEpisodeDuration(episode?.id, episodeDuration))
        }}
        autoPlay={isPlaying}
        src={episode?.audioFile?.file?.url}
        onEnded={handleEnded}
      />
    </>
  )
}

export default AudioController
