import algoliasearch from 'algoliasearch/lite'

export const getDurationStr = (seconds, separator = ':') => {
  const hours = Math.floor(seconds / 3600)
  const mins = Math.floor(seconds / 60) % 60
  const secs = Math.floor(seconds % 60)
  return (hours ? [hours, mins, secs] : [mins, secs])
    .map((time) => `000${time}`.substr(-2))
    .join(separator)
}

export const emailIsValid = (email) => {
  return /\S+@\S+\.\S+/.test(email)
}

/**
 * Returns beautified browser name and version
 * https://stackoverflow.com/questions/5916900/how-can-you-detect-the-version-of-a-browser
 */
export const getBrowser = () => {
  // eslint-disable-next-line
  navigator.sayswho = (function () {
    // eslint-disable-next-line
    const ua = navigator.userAgent
    let tem = null
    let M =
      ua.match(
        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
      ) || []

    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || []
      return 'IE ' + (tem[1] || '')
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\b(OPR|Edge)\/(\d+)/)
      if (tem != null) {
        return tem.slice(1).join(' ').replace('OPR', 'Opera')
      }
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?']
    if ((tem = ua.match(/version\/(\d+)/i)) != null) {
      M.splice(1, 1, tem[1])
    }
    return M.join(' ')
  })()
  // eslint-disable-next-line
  return navigator.sayswho // outputs e.g. `Chrome 62`
}

export const downloadFile = (file, filename) => {
  fetch(file).then((response) => {
    response.blob().then((blob) => {
      const fileURL = window.URL.createObjectURL(blob)
      let alink = document.createElement('a')
      alink.href = fileURL
      alink.download = filename ? `${filename}.pdf` : 'white-paper.pdf'
      alink.click()
    })
  })
}

export const algoliaClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY
)

export const searchClient = {
  ...algoliaClient,
  // prevent searches on empty query bc they are unneccessary and every query costs something
  search(requests) {
    if (requests.every(({ params }) => !params.query)) {
      return Promise.resolve({
        results: requests.map(() => ({
          hits: [],
          nbHits: 0,
          nbPages: 0,
          page: 0,
          processingTimeMS: 0,
        })),
      })
    }
    return algoliaClient.search(requests)
  },
}
