import {
  SET_IS_BLOG_VIEW,
  SET_IS_GLOBAL_NAV_OPEN,
  SET_IS_MOBILE_PRODUCT_NAV_OPEN,
  SET_IS_PLAYING,
  SET_IS_SHOWING,
  SET_IS_LEAVING_PAGE,
  SET_CURRENT_EPISODE_ID,
  SET_EPISODE_DURATION,
  SET_EPISODE_CURRENT_PROGRESS,
  SET_OVERRIDE_PROGRESS,
  SET_PODCAST_PLAYLIST,
  RESET_PLAYER,
  SET_VOLUME,
  SET_AUDIO_IS_LOADING,
} from './types'

export const audioIsLoading = (state = true, { type, payload }) => {
  switch (type) {
    case SET_AUDIO_IS_LOADING:
      return payload
    default:
      return state
  }
}

export const volume = (state = 1, { type, payload }) => {
  switch (type) {
    case SET_VOLUME:
      return payload
    default:
      return state
  }
}

export const isBlogView = (state = false, { type, payload }) => {
  switch (type) {
    case SET_IS_BLOG_VIEW:
      return payload
    default:
      return state
  }
}

export const isLeavingPage = (state = false, { type, payload }) => {
  switch (type) {
    case SET_IS_LEAVING_PAGE:
      return payload
    default:
      return state
  }
}

export const isGlobalNavOpen = (state = false, { type, payload }) => {
  switch (type) {
    case SET_IS_GLOBAL_NAV_OPEN:
      return payload
    default:
      return state
  }
}

export const isMobileProductNavOpen = (state = false, { type, payload }) => {
  switch (type) {
    case SET_IS_MOBILE_PRODUCT_NAV_OPEN:
      return payload
    default:
      return state
  }
}

const podcastPlayerInitialState = {
  isShowing: false,
  isPlaying: false,
  currentEpisodeId: null,
  overrideProgress: -1,
}

export const podcastPlayer = (
  state = podcastPlayerInitialState,
  { type, payload }
) => {
  switch (type) {
    case RESET_PLAYER:
      return { ...state, isPlaying: false }
    case SET_IS_PLAYING:
      return { ...state, isPlaying: payload }
    case SET_IS_SHOWING:
      return { ...state, isShowing: payload }
    case SET_CURRENT_EPISODE_ID:
      return { ...state, currentEpisodeId: payload }
    case SET_OVERRIDE_PROGRESS:
      return { ...state, overrideProgress: payload }
    case SET_PODCAST_PLAYLIST:
      return {
        ...state,
        currentEpisodeId: state.currentEpisodeId || payload?.[0]?.id,
      }
    default:
      return state
  }
}

export const podcastPlaylist = (state = [], { type, payload }) => {
  switch (type) {
    case SET_PODCAST_PLAYLIST:
      return payload
    default:
      return state
  }
}

export const episodeDurations = (state = {}, { type, payload }) => {
  const stateCopy = { ...state }
  switch (type) {
    case SET_EPISODE_DURATION:
      stateCopy[payload.episodeId] = payload.duration
      return stateCopy
    default:
      return state
  }
}

export const episodeCurrentProgresses = (state = {}, { type, payload }) => {
  const stateCopy = { ...state }
  switch (type) {
    case SET_EPISODE_CURRENT_PROGRESS:
      stateCopy[payload.episodeId] = payload.currentProgress
      return stateCopy
    case RESET_PLAYER:
      return {}
    default:
      return state
  }
}
