export const SET_IS_PLAYING = 'SET_IS_PLAYING'
export const SET_CURRENT_EPISODE_ID = 'SET_CURRENT_EPISODE_ID'
export const SET_EPISODE_DURATION = 'SET_EPISODE_DURATION'
export const SET_EPISODE_CURRENT_PROGRESS = 'SET_EPISODE_CURRENT_PROGRESS'
export const SET_OVERRIDE_PROGRESS = 'SET_OVERRIDE_PROGRESS'
export const SET_PODCAST_PLAYLIST = 'SET_PODCAST_PLAYLIST'
export const RESET_PLAYER = 'RESET_PLAYER'
export const SET_IS_BLOG_VIEW = 'SET_IS_BLOG_VIEW'
export const SET_IS_GLOBAL_NAV_OPEN = 'SET_IS_GLOBAL_NAV_OPEN'
export const SET_IS_MOBILE_PRODUCT_NAV_OPEN = 'SET_IS_MOBILE_PRODUCT_NAV_OPEN'
export const SET_VOLUME = 'SET_VOLUME'
export const SET_AUDIO_IS_LOADING = 'SET_AUDIO_IS_LOADING'
export const SET_IS_SHOWING = 'SET_IS_SHOWING'
export const SET_IS_LEAVING_PAGE = 'SET_IS_LEAVING_PAGE'
