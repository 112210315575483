import React from 'react'
import styled from 'styled-components'

const TrackTimes = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  font-size: 12px;
  color: ${({ theme }) => theme.color.tertiary};
`

const CurrentProgress = styled.div`
  color: ${({ theme, currentProgress }) =>
    currentProgress === '00:00'
      ? theme.color.tertiary
      : theme.color.accentSecondary};
`

export default ({ currentProgress, duration, ...rest }) => (
  <TrackTimes {...rest}>
    <CurrentProgress currentProgress={currentProgress}>
      {currentProgress}
    </CurrentProgress>
    <div>{duration}</div>
  </TrackTimes>
)
