import React from 'react'
import styled from 'styled-components'
import OutboundLink from 'components/OutboundLink'
import Logo from 'images/logo.png'
import LogoDark from 'images/logo-dark.png'
import { sizes } from 'styles'

const StyledLogo = styled.img`
  width: 70px;
  padding-left: 2px;
  padding-bottom: 3px;
  vertical-align: bottom;

  @media ${sizes.tabletPortrait} {
    padding-bottom: 1px;
  }
`

const MadeBy = ({ dark, className }) => (
  <div className={className}>
    Made with ❤ by{' '}
    <OutboundLink href="https://safetywing.com">
      <StyledLogo src={dark ? LogoDark : Logo} alt="SafetyWing" />
    </OutboundLink>
  </div>
)

export default MadeBy
