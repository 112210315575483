import React from 'react'
import { Provider } from 'react-redux'
import store from 'state/store'
import AudioController from 'components/AudioController'

export default ({ element }) => {
  return (
    <Provider store={store}>
      {element}
      <AudioController />
    </Provider>
  )
}
