import React, { useState, useCallback, useMemo } from 'react'
import styled, { css } from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import Img from 'gatsby-image'
import { BsFillVolumeUpFill, BsFillVolumeMuteFill } from 'react-icons/bs'
import {
  setVolume,
  setCurrentEpisodeId,
  setIsPlaying,
  setOverrideProgress,
  setEpisodeCurrentProgress,
  playNextEpisode,
  playPrevEpisode,
} from 'state/actions'
import TransitionLink from 'components/TransitionLink'
import { getDurationStr } from 'src/helpers'
import ProgressBar from './ProgressBar'
import VolumeBar from './VolumeBar'
import Controls from './Controls'
import TrackTimes from './TrackTimes'
import { isNil } from 'ramda'
import { sizes } from 'styles'
import { PODCAST_PLAYER_HEIGHT } from 'src/consts'

const ellipsisTextWrap = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const StyledPlayer = styled.div`
  display: grid;
  grid-template-columns: 35% 1fr;
  grid-column-gap: 16px;
  grid-template-areas:
    'cover controls'
    'title title'
    'speakers speakers'
    'progress progress';
  padding: 16px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.color.secondary};
  color: ${({ theme }) => theme.color.white};
  box-shadow: ${({ theme }) => theme.shadow};
  overflow: hidden;

  > :nth-child(3) {
    margin-top: 16px;
  }

  > :nth-child(4) {
    margin-top: 5px;
  }

  > :nth-child(5) {
    margin-top: 15px;
  }

  ${({ inline }) =>
    inline &&
    css`
      grid-template-columns: 1fr;
      grid-template-areas:
        'cover'
        'title'
        'speakers'
        'controls'
        'progress';

      > :nth-child(2) {
        transform: scale(0.7);
        transform-origin: 0 40%;
        margin-top: 4px;
        /* hide sharing button because it's problematic here */
        > :last-child {
          display: none;
        }
      }
      > :nth-child(5) {
        margin-top: 0;
      }
      > :nth-child(3) {
        ${ellipsisTextWrap};
      }
      > :nth-child(4) {
        ${ellipsisTextWrap};
        margin-top: 0;
      }

      @media ${sizes.mobileLarge} {
        max-width: 500px;
        grid-template-columns: 130px 1fr;
        grid-template-areas:
          'cover title'
          'cover speakers'
          'cover controls'
          'cover progress';
        > :nth-child(3) {
          margin-top: 0;
        }
      }
    `}

  ${({ small }) =>
    small &&
    css`
      grid-template-areas:
        'cover title'
        'cover speakers'
        'cover controls'
        'cover progress';

      > :nth-child(2) {
        transform: scale(0.7);
        transform-origin: 0 40%;
        margin-top: 4px;
      }
      > :nth-child(5) {
        margin-top: 0;
      }
      > :nth-child(3),
      > :nth-child(4) {
        ${ellipsisTextWrap};
        margin-top: 0;
      }
    `}

  ${({ sticky }) =>
    sticky &&
    css`
      position: fixed;
      bottom: 0;
      left: 0;
      grid-template-columns: 50px 1fr auto;
      grid-template-areas:
        'cover title controls'
        'cover speakers controls';
      grid-column-gap: 8px;
      box-sizing: border-box;
      width: 100%;
      height: ${PODCAST_PLAYER_HEIGHT}px;
      padding: 8px;
      border-radius: 0;
      overflow: visible;
      z-index: 3;

      /* hide other controls than play button */
      > :nth-child(2) {
        > :not(:first-child) {
          display: none;
        }
      }

      > :nth-child(3),
      > :nth-child(4) {
        ${ellipsisTextWrap};
        margin-top: 0;
        align-self: center;
      }
      > :nth-child(4) {
        align-self: start;
      }
      > :nth-child(5) {
        display: none;
      }

      @media ${sizes.tabletPortrait} {
        > :nth-child(2) {
          > :not(:first-child) {
            display: flex;
          }
          > :last-child,
          > :nth-child(4) {
            display: none;
          }
        }
      }

      @media ${sizes.tabletLandscape} {
        grid-template-columns: 50px fit-content(400px) 1fr auto;
        grid-template-areas:
          'cover title progress controls'
          'cover speakers progress controls';
        grid-column-gap: 16px;

        > :nth-child(2) {
          margin-right: 10px;
          > :not(:first-child) {
            display: flex;
          }
        }

        > :nth-child(5) {
          display: flex;
          margin: 15px 10px 0 10px;
        }
      }
    `}
`

const PodcastImage = styled.div`
  grid-area: cover;
  user-select: none;

  > * {
    border-radius: 5px;
  }
`

const StyledLink = styled(TransitionLink)`
  color: ${({ theme }) => theme.color.white};
  text-decoration: none;
`

const EpisodeTitle = styled.h4`
  grid-area: title;
  margin: 0;
  line-height: 1.4;
  user-select: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  ::before {
    content: ${({ episodeNumber }) => `'Ep. ${episodeNumber}—'`};
    font-weight: normal;
  }
`

const Speakers = styled.div`
  grid-area: speakers;
  opacity: 0.75;
  font-size: 14px;
  user-select: none;
`

const ProgressBarWithVolume = styled.div`
  grid-area: progress;
  display: flex;

  > :first-child {
    flex: 1;
  }
`

const ProgressContainer = styled.div`
  padding-right: 10px;
`

const Volume = styled.div`
  display: flex;
  flex-direction: row;
  width: 80px;
  margin-left: 16px;
  margin-top: -3px;
  cursor: pointer;

  > :last-child {
    flex: 1;
    margin-left: 5px;
    margin-top: 2px;
  }
`

const PodcastPlayer = ({
  sticky,
  small,
  inline,
  initialEpisodeId,
  className,
}) => {
  const dispatch = useDispatch()
  const [
    episode,
    isPlaying,
    episodeDuration,
    episodeCurrentProgress,
    currentEpisodeId,
    podcastPlaylist,
    volume,
    audioIsLoading,
  ] = useSelector((state) => {
    const nextEpisodeId =
      initialEpisodeId || state.podcastPlayer.currentEpisodeId
    return [
      state.podcastPlaylist.find((x) => x.id === nextEpisodeId),
      state.podcastPlayer.isPlaying,
      state.episodeDurations[nextEpisodeId] || 0,
      state.episodeCurrentProgresses[nextEpisodeId] || 0,
      state.podcastPlayer.currentEpisodeId,
      state.podcastPlaylist,
      state.volume,
      state.audioIsLoading,
    ]
  })
  const [currentProgressOverride, setCurrentProgressOverride] = useState(-1)

  const currentEpisodeIndex = useMemo(
    () =>
      podcastPlaylist.indexOf(
        podcastPlaylist.find((x) => x.id === currentEpisodeId)
      ),
    [podcastPlaylist, currentEpisodeId]
  )

  const playerIsPlaying = initialEpisodeId
    ? initialEpisodeId === currentEpisodeId && isPlaying
    : isPlaying

  const handlePlayPause = useCallback(() => {
    if (playerIsPlaying) {
      dispatch(setIsPlaying(false))
      return
    }
    dispatch(setIsPlaying(true))

    if (initialEpisodeId) {
      dispatch(setCurrentEpisodeId(initialEpisodeId))
    }
  }, [playerIsPlaying, initialEpisodeId, dispatch])

  const handlePlayNext = useCallback(() => {
    dispatch(playNextEpisode(currentEpisodeId))
  }, [currentEpisodeId, dispatch])

  const handlePlayPrev = useCallback(() => {
    dispatch(playPrevEpisode(currentEpisodeId))
  }, [currentEpisodeId, dispatch])

  const handleThumbMove = useCallback(
    (percentage) => {
      if (isNil(percentage)) {
        setCurrentProgressOverride(-1)
        return
      }
      setCurrentProgressOverride(percentage * episodeDuration)
    },
    [episodeDuration]
  )

  const handleUpdateCurrentProgress = useCallback(
    (percentage) => {
      const nextProgress = percentage * episodeDuration
      if (initialEpisodeId && initialEpisodeId !== currentEpisodeId) {
        dispatch(setEpisodeCurrentProgress(initialEpisodeId, nextProgress))
        return
      }
      dispatch(setOverrideProgress(nextProgress))
    },
    [episodeDuration, initialEpisodeId, currentEpisodeId, dispatch]
  )

  const handleVolumeChange = () => {
    if (volume === 0) {
      dispatch(setVolume(1))
      return
    }
    dispatch(setVolume(0))
  }

  const currentProgressString = getDurationStr(
    currentProgressOverride === -1
      ? episodeCurrentProgress
      : currentProgressOverride
  )

  if (!episode) {
    return null
  }

  const { title, speakers, image, episodeNumber, slug } = episode

  return (
    <StyledPlayer
      small={small}
      inline={inline}
      sticky={sticky}
      className={className}
    >
      <PodcastImage>
        <Img
          fluid={{
            ...image.fluid,
            aspectRatio: 1 / 1,
          }}
        />
      </PodcastImage>
      <Controls
        showNavigation={!initialEpisodeId}
        showLinks={sticky}
        isPlaying={playerIsPlaying}
        isLoading={audioIsLoading}
        nextDisabled={currentEpisodeIndex >= podcastPlaylist.length - 1}
        prevDisabled={currentEpisodeIndex === 0 && episodeCurrentProgress === 0}
        onPlayClicked={handlePlayPause}
        onPrevClicked={handlePlayPrev}
        onNextClicked={handlePlayNext}
      />
      <StyledLink to={`/episode/${slug}`}>
        <EpisodeTitle title={title} episodeNumber={episodeNumber}>
          {title}
        </EpisodeTitle>
      </StyledLink>
      <Speakers>
        {speakers && speakers.map((speaker) => speaker.name).join(', ')}
      </Speakers>
      <ProgressBarWithVolume>
        <ProgressContainer>
          <ProgressBar
            duration={episodeDuration}
            currentProgress={episodeCurrentProgress}
            onThumbMove={handleThumbMove}
            onUpdatePosition={handleUpdateCurrentProgress}
          />
          <TrackTimes
            duration={getDurationStr(episodeDuration)}
            currentProgress={currentProgressString}
          />
        </ProgressContainer>
        <Volume>
          {volume === 0 ? (
            <BsFillVolumeMuteFill size={20} onClick={handleVolumeChange} />
          ) : (
            <BsFillVolumeUpFill size={20} onClick={handleVolumeChange} />
          )}
          <VolumeBar
            currentVolume={volume}
            onUpdatePosition={(percentage) => {
              dispatch(setVolume(percentage))
            }}
          />
        </Volume>
      </ProgressBarWithVolume>
    </StyledPlayer>
  )
}

export default PodcastPlayer
