import { trackCustomEvent } from 'gatsby-plugin-google-analytics'
import {
  SET_IS_BLOG_VIEW,
  SET_IS_GLOBAL_NAV_OPEN,
  SET_IS_PLAYING,
  SET_CURRENT_EPISODE_ID,
  SET_OVERRIDE_PROGRESS,
  SET_EPISODE_DURATION,
  SET_EPISODE_CURRENT_PROGRESS,
  SET_PODCAST_PLAYLIST,
  RESET_PLAYER,
  SET_VOLUME,
  SET_AUDIO_IS_LOADING,
  SET_IS_SHOWING,
  SET_IS_LEAVING_PAGE,
  SET_IS_MOBILE_PRODUCT_NAV_OPEN,
} from './types'

export const setAudioIsLoading = (isLoading) => ({
  type: SET_AUDIO_IS_LOADING,
  payload: isLoading,
})

export const setIsBlogView = (isBlogView) => ({
  type: SET_IS_BLOG_VIEW,
  payload: isBlogView,
})

export const setIsLeavingPage = (isLeavingPage) => ({
  type: SET_IS_LEAVING_PAGE,
  payload: isLeavingPage,
})

export const setIsGlobalNavOpen = (isGlobalNavOpen) => ({
  type: SET_IS_GLOBAL_NAV_OPEN,
  payload: isGlobalNavOpen,
})

export const setIsMobileProductNavOpen = (isMobileProductNavOpen) => ({
  type: SET_IS_MOBILE_PRODUCT_NAV_OPEN,
  payload: isMobileProductNavOpen,
})

export const setPodcastPlaylist = (playlist) => ({
  type: SET_PODCAST_PLAYLIST,
  payload: playlist,
})

export const setCurrentEpisodeId = (episodeId) => ({
  type: SET_CURRENT_EPISODE_ID,
  payload: episodeId,
})

export const setIsShowing = (isShowing) => ({
  type: SET_IS_SHOWING,
  payload: isShowing,
})

export const setIsPlaying = (isPlaying) => (dispatch) => {
  if (isPlaying) {
    dispatch(setIsShowing(true))
  }
  dispatch({
    type: SET_IS_PLAYING,
    payload: isPlaying,
  })
}

export const setOverrideProgress = (overrideProgress) => ({
  type: SET_OVERRIDE_PROGRESS,
  payload: overrideProgress,
})

export const setEpisodeDuration = (episodeId, duration) => ({
  type: SET_EPISODE_DURATION,
  payload: { episodeId, duration },
})

export const setEpisodeCurrentProgress = (episodeId, currentProgress) => (
  dispatch,
  getState
) => {
  const {
    episodeDurations,
    podcastPlaylist,
    episodeCurrentProgresses,
  } = getState()

  dispatch({
    type: SET_EPISODE_CURRENT_PROGRESS,
    payload: { episodeId, currentProgress },
  })

  const episodeDuration = episodeDurations[episodeId] || 0
  const progressPercentage = Math.floor(
    (currentProgress / episodeDuration) * 100
  )
  const episodeTitle = podcastPlaylist.find((x) => x.id === episodeId)?.title
  const lastProgress = episodeCurrentProgresses[episodeId]
  const lastProgressPercentage = Math.floor(
    (lastProgress / episodeDuration) * 100
  )
  // send event only once when moving to the next 10% of progress
  if (
    progressPercentage % 10 === 0 &&
    lastProgressPercentage < progressPercentage
  ) {
    trackCustomEvent({
      category: 'Podcast',
      action: `Listen-${progressPercentage}%`,
      label: episodeTitle,
    })
    if (typeof window !== 'undefined') {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        category: 'Podcast',
        action: `Listen-${progressPercentage}%`,
        label: episodeTitle,
      })
    }
  }
}

export const setVolume = (volume) => ({
  type: SET_VOLUME,
  payload: volume,
})

export const playNextEpisode = (currentEpisodeId) => (dispatch, getState) => {
  const { podcastPlaylist } = getState()
  const currentEpisodeIndex = podcastPlaylist.indexOf(
    podcastPlaylist.find((x) => x.id === currentEpisodeId)
  )
  if (currentEpisodeIndex < podcastPlaylist.length - 1) {
    const nextEpisodeId = podcastPlaylist[currentEpisodeIndex + 1].id
    dispatch(setCurrentEpisodeId(nextEpisodeId))
    dispatch(setEpisodeCurrentProgress(nextEpisodeId, 0))
  } else {
    dispatch(setIsPlaying(false))
  }
}

export const playPrevEpisode = (currentEpisodeId) => (dispatch, getState) => {
  const { podcastPlaylist, episodeCurrentProgresses } = getState()
  const episodeCurrentProgress = episodeCurrentProgresses[currentEpisodeId] || 0
  const currentEpisodeIndex = podcastPlaylist.indexOf(
    podcastPlaylist.find((x) => x.id === currentEpisodeId)
  )

  // Rewind instead of playing previous episode
  if (episodeCurrentProgress >= 1 || currentEpisodeIndex === 0) {
    dispatch(setOverrideProgress(0))
    return
  }

  const prevEpisodeId = podcastPlaylist[currentEpisodeIndex - 1].id
  dispatch(setCurrentEpisodeId(prevEpisodeId))
  dispatch(setEpisodeCurrentProgress(prevEpisodeId, 0))
}

export const playAll = () => (dispatch, getState) => {
  const firstEpisodeId = getState()?.podcastPlaylist?.[0]?.id
  if (!firstEpisodeId) {
    return
  }
  dispatch(setCurrentEpisodeId(firstEpisodeId))
  dispatch(setEpisodeCurrentProgress(firstEpisodeId, 0))
  dispatch(setIsPlaying(true))
}

export const resetPlayer = () => (dispatch, getState) => {
  const firstEpisodeId = getState()?.podcastPlaylist?.[0]?.id
  if (!firstEpisodeId) {
    return
  }
  dispatch({
    type: RESET_PLAYER,
  })
  dispatch(setCurrentEpisodeId(firstEpisodeId))
}
