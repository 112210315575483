import React from 'react'
import styled, { css } from 'styled-components'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'
import { sizes } from 'styles'

const StyledHoverIcon = styled.a`
  position: relative;
  display: inline-block;
  width: 35px;
  height: 35px;

  :hover {
    opacity: 1;
  }

  @media ${sizes.smallDesktop} {
    width: 25px;
    height: 25px;
  }

  .fill {
    transition: 200ms fill;
    fill: ${({ theme }) => theme.color.accentSecondary};
  }


  ::before {
    box-sizing: border-box;
    display: none;
    content: '${({ hoverText }) => hoverText}';
    position: absolute;
    top: -30px;
    left: 0;
    padding: 5px;
    border-radius: 5px;
    background-color: ${({ theme }) => theme.color.secondary};
    color: ${({ theme }) => theme.color.white};
    white-space: nowrap;
    font-size: 14px;
    line-height: initial;

    ${({ labelOnLeft }) =>
      labelOnLeft &&
      css`
        top: 2px;
        right: 40px;
        left: initial;
        padding: 5px;
      `}
  }

  ${({ showLabel }) =>
    showLabel &&
    css`
      @media ${sizes.smallDesktop} {
        width: 30px;
        height: 30px;
      }
      ::before {
        display: block;
      }
    `}

  :hover {
    ::before {
      display: block;
    }

    .fill {
      fill: ${({ theme }) => theme.color.tertiary};
    }
  }
`

const HoverIcon = ({ children, to, hoverText, labelOnLeft, showLabel }) => (
  <StyledHoverIcon
    href={to}
    target="_blank"
    rel="noopener noreferrer"
    labelOnLeft={labelOnLeft}
    showLabel={showLabel}
    hoverText={hoverText}
    onClick={() => {
      trackCustomEvent({
        category: 'Podcast',
        action: `Link click - ${hoverText}`,
        label: hoverText,
      })
      if (typeof window !== 'undefined') {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          category: 'Podcast',
          action: `Link click - ${hoverText}`,
          label: hoverText,
        })
      }
    }}
  >
    {children}
  </StyledHoverIcon>
)

export default HoverIcon
