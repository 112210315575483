import React from 'react'
import styled, { css, keyframes } from 'styled-components'
import { useSelector } from 'react-redux'
import { BsFillCaretRightFill } from 'react-icons/bs'
import { FaStepBackward, FaStepForward, FaEllipsisV } from 'react-icons/fa'
import { GiPauseButton } from 'react-icons/gi'
import { FiShare } from 'react-icons/fi'
import PodcastLinks from 'components/PodcastLinks'
import LinksContainer from '../LinksContainer'

const StyledControls = styled.div`
  grid-area: controls;
  position: relative;
  display: flex;
  align-items: center;
`

const PlayIcon = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.color.tertiary};
  color: ${({ theme }) => theme.color.white};
  box-shadow: ${({ theme }) => theme.shadow};
  cursor: pointer;
  transition: transform 200ms;
  transform: scale3d(1, 1, 1);
  :hover {
    transform: scale3d(1.07, 1.07, 1);
  }
`

const LoadingIndicator = styled.div`
  width: 12px;
  height: 12px;
  border: 3px solid #fff;
  border-top-color: transparent;
  border-radius: 50%;
  animation-name: ${keyframes`
    0% {
     transform: rotate(45deg);
    }
    100% {
      transform: rotate(405deg);
    }
  `};
  animation-duration: 800ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
`

const SmallIcon = styled.div`
  width: 26px;
  height: 26px;
  margin-left: 10px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.color.primary};
  box-shadow: ${({ theme }) => theme.shadow};
  transition: transform 200ms;
  transform: scale3d(1, 1, 1);
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};

  ${({ disabled }) =>
    !disabled &&
    css`
      :hover {
        transform: scale3d(1.07, 1.07, 1);
      }
    `}

  > * {
    opacity: 50%;
  }
`

const Controls = ({
  showNavigation,
  showLinks,
  isPlaying,
  isLoading,
  nextDisabled,
  prevDisabled,
  onPlayClicked,
  onPrevClicked,
  onNextClicked,
}) => {
  const [currentEpisodeId, podcastPlaylist] = useSelector((state) => {
    return [state.podcastPlayer.currentEpisodeId, state.podcastPlaylist]
  })
  const currentEpisode = podcastPlaylist.find((x) => x.id === currentEpisodeId)
  const {
    applePodcastsLink,
    googlePodcastsLink,
    spotifyLink,
    iHeartRadioLink,
    episodeNumber,
  } = currentEpisode

  const hasExternalLinks =
    !!applePodcastsLink ||
    !!googlePodcastsLink ||
    !!spotifyLink ||
    !!iHeartRadioLink

  const renderPlayButton = () => {
    if (isLoading && isPlaying) {
      return <LoadingIndicator />
    }
    if (isPlaying) {
      return <GiPauseButton size="20" />
    }
    return <BsFillCaretRightFill size="25" />
  }

  return (
    <StyledControls>
      <PlayIcon onClick={onPlayClicked}>{renderPlayButton()}</PlayIcon>
      {showNavigation && (
        <>
          <SmallIcon
            onClick={() => {
              if (prevDisabled) {
                return
              }
              onPrevClicked()
            }}
            disabled={prevDisabled}
          >
            <FaStepBackward size="14" />
          </SmallIcon>
          <SmallIcon
            onClick={() => {
              if (nextDisabled) {
                return
              }
              onNextClicked()
            }}
            disabled={nextDisabled}
          >
            <FaStepForward size="14" />
          </SmallIcon>
        </>
      )}
      <LinksContainer icon={<FiShare />}>
        <PodcastLinks
          vertical
          variant="sharing"
          episodeNumber={episodeNumber}
        />
      </LinksContainer>
      {hasExternalLinks && showLinks && currentEpisode && (
        <LinksContainer icon={<FaEllipsisV />}>
          <PodcastLinks
            vertical
            showLabels
            applePodcastsLink={applePodcastsLink}
            googlePodcastsLink={googlePodcastsLink}
            spotifyLink={spotifyLink}
            iHeartRadioLink={iHeartRadioLink}
          />
        </LinksContainer>
      )}
    </StyledControls>
  )
}

export default Controls
