import React, { useRef, useCallback, useLayoutEffect, useState } from 'react'
import styled from 'styled-components'
import { clamp } from 'ramda'

const StyledProgressBar = styled.div`
  position: relative;
  height: 15px;
  display: flex;
  align-items: center;
  cursor: pointer;

  > * {
    pointer-events: none;
  }
`

const Track = styled.div`
  width: 100%;
  height: 5px;
  border-radius: 2px;
  background-color: ${({ theme }) => theme.color.textSecondary};
  cursor: pointer;
`

const Progress = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  width: 0;
  height: 5px;
  border-radius: 2px;
  background-color: ${({ theme }) => theme.color.tertiary};
  cursor: pointer;
  transition: width 100ms linear;
`

const ProgressBar = ({
  currentVolume,
  // Emitted after thumb has been let go, on mouse up and touch up
  onUpdatePosition,
  className,
}) => {
  const [progressBarWidth, setProgressBarWidth] = useState(0)
  const ref = useRef(null)
  const lastPercentageRef = useRef(0)

  const handleDrag = useCallback(
    (e) => {
      e.preventDefault()

      const el = ref && ref.current
      if (!el) {
        return
      }

      const width = el.clientWidth

      const getPercentage = (e) => {
        const event = e.touches ? e.touches[0] : e

        const rect = el.getBoundingClientRect()
        const x = event.clientX - rect.left
        const percentage = clamp(0, 1, x / width)
        return percentage
      }

      const handleMove = (e) => {
        const percentage = getPercentage(e)
        onUpdatePosition && onUpdatePosition(percentage)
      }

      const handleEnd = () => {
        window.removeEventListener('mousemove', handleMove)
        window.removeEventListener('mouseup', handleEnd)
        window.removeEventListener('touchmove', handleMove)
        window.removeEventListener('touchend', handleEnd)
      }

      handleMove(e)
      window.addEventListener('mousemove', handleMove)
      window.addEventListener('mouseup', handleEnd)
      window.addEventListener('touchmove', handleMove)
      window.addEventListener('touchend', handleEnd)

      return () => {
        window.removeEventListener('mousemove', handleMove)
        window.removeEventListener('mouseup', handleEnd)
        window.removeEventListener('touchmove', handleMove)
        window.removeEventListener('touchend', handleEnd)
      }
    },
    [ref, lastPercentageRef, onUpdatePosition, progressBarWidth]
  )

  useLayoutEffect(() => {
    if (!ref.current) return
    const setWidth = () => setProgressBarWidth(ref.current.clientWidth)
    setWidth()
    window.addEventListener('resize', setWidth)
    return () => {
      window.removeEventListener('resize', setWidth)
    }
  }, [ref.current])

  return (
    <StyledProgressBar
      ref={ref}
      className={className}
      onMouseDown={handleDrag}
      onTouchStart={handleDrag}
    >
      <Track />
      <Progress
        style={{
          width: `${currentVolume * 100}%`,
        }}
      />
    </StyledProgressBar>
  )
}

export default ProgressBar
