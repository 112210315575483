import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import Navigation from '@safetywing-tech/global-navbar'
import BrNavbar from 'components/Navbar'
import { setIsGlobalNavOpen } from 'state/actions'

const GlobalNavigation = ({ pathname }) => {
  const dispatch = useDispatch()
  const mainSiteUrl = 'https://safetywing.com'
  const [isProductNavOpen, setIsProductNavOpen] = useState(false)
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    dispatch(setIsGlobalNavOpen(isVisible))
  }, [isVisible])

  return (
    <>
      <Navigation
        pageType={{
          isHomepage: pathname === '/',
        }}
        mainSiteUrl={mainSiteUrl}
        isProductNavOpen={isProductNavOpen}
        isGlobalNavVisible={(isVisible) => setIsVisible(isVisible)}
      >
        <BrNavbar
          isProductNavOpen={(isNavOpen) => setIsProductNavOpen(isNavOpen)}
        />
      </Navigation>
    </>
  )
}

export default GlobalNavigation
