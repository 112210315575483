import React, { useState } from 'react'
import styled from 'styled-components'
import Button from 'components/Button'
import { subscribe } from 'api'
import { emailIsValid } from 'src/helpers'
import { sizes } from 'styles'

const StyledSubscribe = styled.div`
  width: 100%;
`

const EmailInput = styled.input`
  flex: 1;
  box-sizing: border-box;
  width: 100%;
  padding: 7px 10px;
  margin-right: 10px;
  margin-bottom: 20px;
  border: 1px solid #7addb6;
  border-radius: 5px;
  font-size: 16px;

  @media ${sizes.tabletPortrait} {
    margin-bottom: 0;
  }

  ::placeholder {
    color: #bebebe;
  }

  :focus,
  :active {
    outline: #7addb6 auto 5px;
  }
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media ${sizes.tabletPortrait} {
    flex-direction: row;
    align-items: center;
  }
`

const ErrorText = styled.div`
  padding-top: 10px;
  color: red;
`

const Success = styled.div`
  padding-top: 20px;
`

const Subscribe = ({ buttonText, children, className }) => {
  const [email, setEmail] = useState('')
  const [showInvalidEmailWarning, setShowInvalidEmailWarning] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [showFailure, setShowFailure] = useState(false)
  const [isSending, setIsSending] = useState(false)
  return (
    <StyledSubscribe className={className}>
      {children}
      <Form
        onSubmit={async (e) => {
          e.preventDefault()
          if (!emailIsValid(email)) {
            setShowInvalidEmailWarning(true)
            return
          }
          setIsSending(true)
          const res = await subscribe(email)
          setIsSending(false)
          if (res.ok) {
            setShowSuccess(true)
          } else {
            setShowFailure(true)
          }
        }}
      >
        <EmailInput
          type="text"
          placeholder="Your email"
          value={email}
          onChange={(e) => {
            setShowSuccess(false)
            setShowFailure(false)
            if (emailIsValid(email)) {
              setShowInvalidEmailWarning(false)
            }
            setEmail(e.target.value)
          }}
          ontouchstart=""
        />
        <Button type="submit" disabled={isSending}>
          {buttonText || 'Subscribe'}
        </Button>
      </Form>
      {showInvalidEmailWarning && (
        <ErrorText>Please enter a valid email</ErrorText>
      )}
      {showFailure && <ErrorText>Something went wrong!</ErrorText>}
      {showSuccess && <Success>Thank you for subscribing!</Success>}
    </StyledSubscribe>
  )
}

export default Subscribe
