import React, { useState, useRef, useEffect } from 'react'
import { Link, navigate } from 'gatsby'
import { useDispatch } from 'react-redux'
import { setIsLeavingPage } from 'state/actions'

const isSamePath = (to) =>
  typeof window !== 'undefined' &&
  window.location.pathname.replace(/\/$/, '') === to.replace(/\/$/, '')

const TransitionLink = ({ to, disabled, ...restProps }) => {
  const dispatch = useDispatch()
  const isMounted = useRef()
  const [clicked, setClicked] = useState(false)

  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  return (
    <Link
      to={to}
      onClick={(e) => {
        e.preventDefault()
        if (clicked || isSamePath(to) || disabled) {
          return
        }
        setClicked(true)
        dispatch(setIsLeavingPage(true))
        setTimeout(() => {
          const append = to.split('')[to.length - 1] === '/' ? '' : '/'
          navigate(`${to}${append}`)
          if (isMounted.current) setClicked(false)
        }, 150)
      }}
      {...restProps}
    />
  )
}

export default TransitionLink
