import React from 'react'
import styled from 'styled-components'
import { FaTwitter, FaFacebookF, FaLinkedinIn } from 'react-icons/fa'
import HoverIcon from '../HoverIcon'
import { sizes } from 'styles'

const IconContainer = styled.div`
  width: 35px;
  height: 35px;
  background-color: ${({ theme }) => theme.color.accentSecondary};
  color: ${({ theme }) => theme.color.white};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;

  > svg {
    width: 70%;
    height: 70%;
  }

  @media ${sizes.smallDesktop} {
    width: 30px;
    height: 30px;
  }
`

const HoverContainer = styled.div`
  :hover {
    ${IconContainer} {
      background-color: ${({ theme }) => theme.color.tertiary};
    }
  }
`

const PodcastSharingLinks = ({ episodeNumber }) => {
  return (
    <>
      <HoverContainer>
        <HoverIcon
          to={`https://twitter.com/intent/tweet?url=https%3A%2F%2Fbuildingremotely.com%2Fepisode%2F${episodeNumber}`}
          hoverText="Share episode on Twitter"
          showLabel
          labelOnLeft
        >
          <IconContainer>
            <FaTwitter />
          </IconContainer>
        </HoverIcon>
      </HoverContainer>
      <HoverContainer>
        <HoverIcon
          to={`https://www.facebook.com/dialog/share?app_id=376183856180246&display=popup&href=https%3A%2F%2Fbuildingremotely.com%2Fepisode%2F${episodeNumber}`}
          hoverText="Share episode on Facebook"
          showLabel
          labelOnLeft
        >
          <IconContainer>
            <FaFacebookF />
          </IconContainer>
        </HoverIcon>
      </HoverContainer>
      <HoverContainer>
        <HoverIcon
          to={`https://www.linkedin.com/sharing/share-offsite/?url=https%3A%2F%2Fbuildingremotely.com%2Fepisode%2F${episodeNumber}`}
          hoverText="Share episode on LinkedIn"
          showLabel
          labelOnLeft
        >
          <IconContainer>
            <FaLinkedinIn />
          </IconContainer>
        </HoverIcon>
      </HoverContainer>
    </>
  )
}

export default PodcastSharingLinks
