import React from 'react'

const Apple = () => (
  <svg viewBox="0 0 25 25" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>apple</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="apple"
        transform="translate(-1.000000, 0.000000)"
        fillRule="nonzero"
        className="fill"
      >
        <path
          d="M25.5,21.7 C24.9,23.2 23.7,24.3 22.1,24.7 C21.1,24.9 20.1,25.1 19.1,25 L7.9,25 C6.9,25 5.9,24.9 4.9,24.7 C3.4,24.2 2.2,23.1 1.5,21.7 C1.1,20.6 1,19.4 1,18.2 L1,18.2 L1,6.9 L1,6.9 C0.9,5.7 1.1,4.5 1.5,3.4 C2.1,1.9 3.3,0.8 4.9,0.4 C5.9,0.2 6.9,-4.99600361e-16 7.9,0.1 L19.1,0.1 C20.1,0.1 21.1,0.2 22.1,0.4 C23.6,0.9 24.8,2 25.5,3.4 C25.9,4.5 26,5.7 26,6.9 L26,18.1 C26.1,19.3 25.9,20.5 25.5,21.7 Z M15.3,14.4 C14.8,14 14.2,13.7 13.5,13.8 C12.8,13.8 12.2,14 11.7,14.4 C11.5,14.6 11.4,14.8 11.4,15.1 C11.4,15.7 11.4,16.4 11.4,17 C11.5,17.8 11.6,18.8 11.8,19.8 C11.9,20.3 12,20.8 12.1,21.2 C12.3,21.8 12.9,22.1 13.6,22.1 C14.2,22.2 14.8,21.8 15.1,21.2 C15.2,20.7 15.3,20.3 15.4,19.8 C15.6,18.8 15.7,17.7 15.8,17 C15.9,16.4 15.9,15.7 15.8,15.1 C15.6,14.8 15.5,14.6 15.3,14.4 L15.3,14.4 Z M11.5,10.8 C11.5,11.9 12.4,12.8 13.5,12.8 C14.6,12.8 15.5,11.9 15.5,10.8 C15.5,9.7 14.6,8.8 13.5,8.8 C13.5,8.8 13.5,8.8 13.5,8.8 C12.4,8.8 11.5,9.7 11.5,10.8 L11.5,10.8 Z M13.5,2.8 C8.6,2.8 4.7,6.8 4.7,11.6 C4.7,15.3 7.1,18.6 10.6,19.9 C10.7,19.9 10.7,19.9 10.8,19.8 C10.8,19.8 10.8,19.8 10.8,19.7 C10.8,19.4 10.7,19.1 10.7,18.8 C10.7,18.7 10.6,18.6 10.5,18.6 C6.6,16.9 4.8,12.4 6.5,8.5 C8.2,4.6 12.7,2.8 16.6,4.5 C20.5,6.2 22.3,10.7 20.6,14.6 C19.8,16.4 18.4,17.8 16.6,18.6 C16.5,18.6 16.4,18.7 16.4,18.8 C16.4,19.1 16.3,19.4 16.3,19.7 C16.3,19.8 16.3,19.8 16.4,19.8 C16.4,19.8 16.4,19.8 16.5,19.8 C21.1,18.2 23.5,13.2 21.9,8.6 C20.6,5.1 17.2,2.8 13.5,2.8 L13.5,2.8 Z M13.3,6.8 C15.9,6.7 18.1,8.7 18.2,11.4 C18.3,12.8 17.7,14.1 16.7,15.1 C16.6,15.2 16.6,15.3 16.6,15.4 C16.6,15.7 16.6,16.1 16.6,16.4 C16.6,16.5 16.6,16.5 16.7,16.5 C16.7,16.5 16.7,16.5 16.8,16.5 C19.5,14.7 20.2,11 18.4,8.3 C16.6,5.6 12.9,4.9 10.2,6.7 C7.5,8.5 6.8,12.2 8.6,14.9 C9,15.5 9.5,16 10.2,16.5 C10.3,16.5 10.3,16.5 10.4,16.5 C10.4,16.5 10.4,16.5 10.4,16.4 C10.4,16.1 10.4,15.7 10.4,15.4 C10.4,15.3 10.4,15.2 10.3,15.1 C8.4,13.3 8.3,10.3 10.1,8.4 C10.9,7.4 12.1,6.8 13.3,6.8 Z"
          id="Path_954"
        ></path>
      </g>
    </g>
  </svg>
)

export default Apple
