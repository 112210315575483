import React, { useRef, useLayoutEffect, useState } from 'react'
import styled from 'styled-components'

const Positioner = styled.div`
  position: relative;
`

const StyledLinksContainer = styled.div`
  position: absolute;
  right: -12px;
  bottom: 50px;
  padding: 10px;
  justify-content: flex-end;
  align-items: flex-end;
`

const SmallIcon = styled.div`
  width: 26px;
  height: 26px;
  margin-left: 10px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.color.primary};
  box-shadow: ${({ theme }) => theme.shadow};
  transition: transform 200ms;
  transform: scale3d(1, 1, 1);
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  :hover {
    transform: scale3d(1.07, 1.07, 1);
  }

  > * {
    opacity: 50%;
  }
`

const LinksContainer = ({ children, icon }) => {
  const linksRef = useRef()
  const [linksOpen, setLinksOpen] = useState(false)

  const handleClickOutside = (e) => {
    if (linksRef.current.contains(e.target)) {
      return
    }
    setLinksOpen(false)
  }

  useLayoutEffect(() => {
    window.addEventListener('click', handleClickOutside)
    return () => {
      window.removeEventListener('click', handleClickOutside)
    }
  }, [])

  return (
    <Positioner ref={linksRef}>
      <StyledLinksContainer>{linksOpen && children}</StyledLinksContainer>
      <SmallIcon onClick={() => setLinksOpen(!linksOpen)}>{icon}</SmallIcon>
    </Positioner>
  )
}

export default LinksContainer
