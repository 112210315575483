import React from 'react'

const Spotify = () => (
  <svg
    version="1.1"
    id="icon-spotify"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 25 25"
  >
    <g>
      <path
        className="fill"
        d="M7.4,9.2c-0.4,0.1-0.7,0.6-0.5,1c0.1,0.4,0.6,0.7,1,0.5c2.6-0.8,7.1-0.6,9.9,1v0c0.4,0.2,0.9,0.1,1.1-0.3
		c0.2-0.4,0.1-0.9-0.3-1.1C15.4,8.5,10.4,8.3,7.4,9.2z"
      />
      <path
        className="fill"
        d="M16.4,15.7c-2.5-1.4-5.5-1.7-8.2-0.9c0,0,0,0,0,0c-0.3,0.1-0.5,0.4-0.4,0.6c0.1,0.3,0.4,0.5,0.6,0.4
		c2.5-0.7,5.2-0.4,7.5,0.8c0,0,0,0,0,0c0.2,0.2,0.6,0.1,0.7-0.2h0c0,0,0,0,0,0C16.8,16.2,16.7,15.8,16.4,15.7z"
      />
      <path
        className="fill"
        d="M17.4,13.2c-2.9-1.6-6.3-2-9.5-1.1c0,0,0,0,0,0c-0.3,0.1-0.5,0.5-0.4,0.8c0.1,0.3,0.5,0.5,0.8,0.4
		c2.8-0.8,5.9-0.4,8.5,1c0.3,0.2,0.7,0.1,0.9-0.2C17.8,13.8,17.7,13.4,17.4,13.2z"
      />
      <path
        className="fill"
        d="M20,0H5C2.2,0,0,2.2,0,5v15c0,2.8,2.2,5,5,5h15c2.8,0,5-2.2,5-5V5C25,2.2,22.8,0,20,0z M12.7,21.2
		c-4.7,0-8.5-3.8-8.5-8.5C4.2,8,8,4.2,12.7,4.2c4.7,0,8.5,3.8,8.5,8.5C21.2,17.4,17.4,21.2,12.7,21.2z"
      />
    </g>
  </svg>
)

export default Spotify
