import { createGlobalStyle } from 'styled-components'
import get from 'lodash/get'

export const SCREEN_SIZES = {
  largeDesktop: 1600,
  desktop: 1200,
  smallDesktop: 1024,
  tabletLandscape: 900,
  tabletPortrait: 600,
  mobileLarge: 425,
  mobileMedium: 375,
}

export const sizes = {
  mobileMedium: `(min-width: ${SCREEN_SIZES.mobileMedium}px)`,
  mobileLarge: `(min-width: ${SCREEN_SIZES.mobileLarge}px)`,
  tabletPortrait: `(min-width: ${SCREEN_SIZES.tabletPortrait}px)`,
  tabletLandscape: `(min-width: ${SCREEN_SIZES.tabletLandscape}px)`,
  smallDesktop: `(min-width: ${SCREEN_SIZES.smallDesktop}px)`,
  desktop: `(min-width: ${SCREEN_SIZES.desktop}px)`,
  largeDesktop: `(min-width: ${SCREEN_SIZES.largeDesktop}px)`,
  extraLargeDesktop: `(min-width: ${SCREEN_SIZES.extraLargeDesktop}px)`,
  monitor: `(min-width: ${SCREEN_SIZES.monitor}px)`,
}

const getValue = (props, variant) =>
  typeof variant === 'function' ? variant(props) : variant

// Creates a function that returns a matching prop value when passed a string
export const variants = (prop, values) => (props) => {
  const variant = props[prop] && values[props[prop]]
  return variant && getValue(props, variant)
}

export const th = (path) => (props) => get(props.theme, path)

// All the code that controls vertical stacking is in one place. The idea being
// that you could slip something in between later if need be, which you couldn't
// if you did 1, 2, 3, etc, because z-index doesn't support decimals.
export const zIndexes = {
  notification: 9000,
  overlay: 8000,
  modal: 7000,
  navigation: 6000,
  dropdown: 5000,
}

export const theme = {
  color: {
    primary: '#4343B5',
    secondary: '#333377',
    tertiary: '#5656C2',
    backgroundsBorder: '#E8E8F6',
    backgrounds: '#F6F6FB',
    lightLilac: '#b2b1ff5c',
    footer: '#535388',
    searchHighlight: '#c0c0fb',
    text: '#273C49',
    textSecondary: '#24245A',
    accent: '#E4F5EE',
    accentSecondary: '#7ADDB6',
    white: '#FFF',
    black: '#273C49',
    lightBlack: '#385264',
    disabled: '#b1b1b1',
    turquoise: '#5CC6C7',
    darkSlateGray: '#364c5a',
    yellow: '#EDCE68',
    transparentWhite: [...new Array(10).keys()].map(
      // eg. transparentWhite.9 has opacity 0.9
      (i) => `rgba(255, 255, 255, ${i / 10})`
    ),
    transparentBlack: [...new Array(10).keys()].map(
      // eg. transparentBlack.9 has opacity 0.9
      (i) => `rgba(39, 60, 73, ${i / 10})`
    ),
  },
  font: {
    text: 'Helvetica, Arial, sans-serif',
    // TODO headings are not Times, change to right font
    headings: 'Times, serif',
  },
  shadow: '0px 3px 6px #00000029',
}

export const GlobalStyle = createGlobalStyle`
  html, body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    -webkit-font-smoothing: antialiased;
    font-family: ${({ theme }) => theme.font.text};
    font-size: 16px;
    color: ${({ theme }) => theme.color.textSecondary};
  }

  h1 {
    margin: 0;
    font-family: ${({ theme }) => theme.font.headings};
    font-weight: bold;
    font-size: 40px;
    line-height: 43px;
    letter-spacing: -0.5px;

    @media ${sizes.desktop} {
      font-size: 50px;
      line-height: 50px;
    }
  }

  h2 {
    font-size: 40px;

    @media ${sizes.tabletPortrait} {
      font-size: 50px;
    }

    @media ${sizes.desktop} {
      font-size: 60px;
      line-height: 64px;
    }
  }

  p {
    line-height: 26px;
  }

  a {
    color: ${({ theme }) => theme.color.tertiary};
    cursor: pointer;
    opacity: 1;
    transition: opacity 100ms;

    :hover {
      opacity: 0.8;
    }
  }

  h1, h2, h3, h4, h5, p, span, b, a, div {
    ::selection {
      background: ${({ theme }) => theme.color.accent};
    }
  }
`
