import React from 'react'
import styled from 'styled-components'
import ChapterNavigation from 'components/ChapterNavigation'
import { sizes } from 'styles'

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: ${({ hide }) => (hide ? 0 : 1)};
  pointer-events: ${({ hide }) => (hide ? 'none' : 'initial')};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.color.secondary}96;
  transition: opacity 200ms;
  z-index: 6001;

  @media ${sizes.tabletLandscape} {
    display: none;
  }
`

const Content = styled.div`
  width: 350px;
  max-width: 90%;
  height: 80%;
  display: flex;
  flex-direction: column;
`

const StyledChapterNavigation = styled(ChapterNavigation)`
  height: 100%;
  background-color: ${({ theme }) => theme.color.white};
`

const MobileCloseIcon = styled.div`
  position: relative;
  align-self: flex-end;
  width: 60px;
  height: 60px;
  min-width: 60px;
  min-height: 60px;
  margin-top: -25px;
  margin-bottom: -35px;
  margin-right: -10px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.color.tertiary};
  z-index: 3;

  > div {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 30px;
    height: 4px;
    border-radius: 5px;
    background-color: ${({ theme }) => theme.color.white};

    :first-child {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    :last-child {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
`

const MobileChapterNavOverlay = ({ hide, pathname, onClose, className }) => {
  return (
    <Background className={className} hide={hide}>
      <Content>
        <MobileCloseIcon onClick={onClose}>
          <div />
          <div />
        </MobileCloseIcon>
        <StyledChapterNavigation
          location={pathname}
          hide={hide}
          onClose={onClose}
        />
      </Content>
    </Background>
  )
}

export default MobileChapterNavOverlay
