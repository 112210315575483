import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import { BsFillCaretUpFill, BsFillCaretDownFill } from 'react-icons/bs'
import TransitionLink from 'components/TransitionLink'

const Background = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 360px;
  padding: 15px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.color.backgrounds};
  transition: opacity 200ms;
  z-index: 1;
`

const Scroller = styled.div`
  box-sizing: border-box;
  height: 100%;
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: ${({ theme }) => theme.color.textSecondary} #d2d2dd;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: #d2d2dd;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.color.textSecondary};
    border-radius: 10px;
  }
`

const StyledChapterContainer = styled.div`
  padding: 10px;
`

const ChapterTitleContainer = styled.div`
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  color: ${({ $isActive, theme }) =>
    $isActive ? theme.color.tertiary : theme.color.textSecondary};
  cursor: pointer;

  > svg {
    filter: ${({ theme }) => `drop-shadow(${theme.shadow})`};
  }
`

const Chapter = styled(TransitionLink)`
  padding-left: 12px;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${({ $isActive, theme }) =>
    $isActive ? theme.color.tertiary : theme.color.textSecondary};
  font-weight: bold;
  font-size: 18px;
`

const Topics = styled.div`
  position: relative;
  padding-left: 30px;

  ::before {
    content: ' ';
    position: absolute;
    left: 9px;
    top: 8px;
    height: 100%;
    width: 2px;
    background-color: #c4c4c4;
    opacity: 0.5;
  }
`

const Topic = styled(TransitionLink)`
  position: relative;
  max-width: 90%;
  padding-top: 15px;
  padding-left: 5px;
  display: flex;
  flex-direction: row;
  font-weight: normal;
  line-height: 22px;
  text-decoration: none;
  color: ${({ $isActive, theme }) =>
    $isActive ? theme.color.tertiary : theme.color.textSecondary};
  font-weight: ${({ $isActive }) => ($isActive ? 'bold' : 'normal')};

  ${({ $isActive }) =>
    $isActive &&
    css`
      ::before {
        content: ' ';
        position: absolute;
        left: -22px;
        top: 8px;
        height: 100%;
        width: 4px;
        border-radius: 5px;
        background-color: ${({ theme }) => theme.color.tertiary};
      }
    `}
`

const TopicNumber = styled.span`
  font-style: italic;
  font-family: ${({ theme }) => theme.font.headings};
  color: ${({ theme }) => theme.color.tertiary};
  padding-right: 10px;
`

const ChapterContainer = ({
  chapter,
  isActiveChapter,
  activeTopic,
  onClose,
}) => {
  const [isOpen, setIsOpen] = useState(isActiveChapter)
  return (
    <StyledChapterContainer>
      <ChapterTitleContainer $isActive={isActiveChapter}>
        {isOpen ? (
          <BsFillCaretUpFill size={22} onClick={() => setIsOpen(!isOpen)} />
        ) : (
          <BsFillCaretDownFill size={22} onClick={() => setIsOpen(!isOpen)} />
        )}
        <Chapter to={`/chapter/${chapter.slug}`} $isActive={isActiveChapter}>
          {chapter.title}
        </Chapter>
      </ChapterTitleContainer>
      {isOpen && (
        <Topics>
          {chapter.topics &&
            chapter.topics.map((topic, i) => (
              <Topic
                key={i}
                to={`/chapter/${chapter.slug}/${topic.slug}`}
                $isActive={activeTopic && activeTopic === topic.slug}
                onClick={onClose && onClose}
              >
                <TopicNumber>{i < 9 ? `0${i + 1}` : i + 1}</TopicNumber>
                <span>{topic.title}</span>
              </Topic>
            ))}
        </Topics>
      )}
    </StyledChapterContainer>
  )
}

const ChapterNavigation = ({ hide, location, onClose, className }) => {
  const data = useStaticQuery(graphql`
    query chapterNavigationQuery {
      allContentfulLibraryPage {
        edges {
          node {
            chapters {
              id
              slug
              title
              topics {
                title
                slug
              }
            }
          }
        }
      }
    }
  `)
  const { chapters } = data.allContentfulLibraryPage.edges[0].node
  const activeChapter = location.includes('chapter') && location.split('/')[2]
  const activeTopic = location.includes('chapter') && location.split('/')[3]
  return (
    <Background className={className} hide={hide}>
      <Scroller>
        {chapters.map((chapter, i) => {
          const isActiveChapter =
            activeChapter && activeChapter === chapter.slug
          return (
            <ChapterContainer
              key={i}
              chapter={chapter}
              isActiveChapter={isActiveChapter}
              activeTopic={activeTopic}
              onClose={onClose}
            />
          )
        })}
      </Scroller>
    </Background>
  )
}

export default ChapterNavigation
