import React from 'react'

const Iheart = () => (
  <svg
    version="1.1"
    id="icon-iheart"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 25 25"
  >
    <g>
      <path
        className="fill"
        d="M12.6,9c-0.9,0-1.6,0.7-1.6,1.6s0.7,1.6,1.6,1.6h0c0.9,0,1.6-0.7,1.6-1.6C14.2,9.7,13.5,9,12.6,9z"
      />
      <path
        className="fill"
        d="M9.9,12.7c-0.9-0.5-1.4-1.4-1.5-2.4c0-0.8,0.5-1.6,1.2-2C9.9,8.3,9.9,8,9.9,7.8S9.5,7.6,9.3,7.7
		c-1,0.5-1.6,1.5-1.6,2.7v0c0,1.3,0.7,2.4,1.8,3c0.2,0.1,0.4,0,0.5-0.1C10.2,13.1,10.1,12.8,9.9,12.7z"
      />
      <path
        className="fill"
        d="M5.4,10.2L5.4,10.2C5.5,9.1,6,8,6.9,7.4C7.1,7.3,7.2,7,7.1,6.9C6.9,6.7,6.7,6.6,6.5,6.7
		c-1.1,0.8-1.8,2.1-1.8,3.4v0c0.1,1.8,1,3.4,2.4,4.4c0.2,0.1,0.4,0.1,0.5,0c0.1-0.2,0.1-0.4,0-0.5C6.4,13.2,5.6,11.8,5.4,10.2z"
      />
      <path
        className="fill"
        d="M17.4,10.4c0-1.1-0.6-2.2-1.6-2.7c-0.2-0.1-0.4,0-0.5,0.2c-0.1,0.2,0,0.4,0.2,0.5c0.7,0.4,1.2,1.2,1.2,2
		c0,1-0.6,1.9-1.5,2.4C15,12.8,15,13,15.1,13.2s0.3,0.2,0.5,0.1l0,0C16.7,12.8,17.4,11.6,17.4,10.4L17.4,10.4z"
      />
      <path
        className="fill"
        d="M18.6,6.7c-0.2-0.1-0.4-0.1-0.5,0.1C18,7,18,7.3,18.2,7.4c0.9,0.7,1.5,1.7,1.5,2.8v0c-0.1,1.5-0.9,3-2.2,3.9
		c-0.2,0.1-0.2,0.4,0,0.5c0.1,0.2,0.4,0.2,0.5,0c1.4-1.1,2.3-2.7,2.4-4.4l0,0C20.4,8.8,19.7,7.5,18.6,6.7z"
      />
      <path
        className="fill"
        d="M20,0H5C2.2,0,0,2.2,0,5v15c0,2.8,2.2,5,5,5h15c2.8,0,5-2.2,5-5V5C25,2.2,22.8,0,20,0z M20.1,15l-4.9,4.6
		c-0.1,0.1-0.2,0.1-0.3,0.1c-0.2,0-0.4-0.2-0.4-0.4v-3.6c0-1-0.8-1.9-1.9-1.9c-1,0-1.9,0.8-1.9,1.9v3.6c0,0.1,0,0.2-0.1,0.3
		c-0.2,0.2-0.4,0.2-0.6,0L5.1,15c-1.3-1.3-2.5-2.6-2.5-4.5c0,0,0,0,0,0C2.6,7.5,5.1,5,8,5c1.8,0,3.5,0.9,4.5,2.4
		C13.6,6,15.3,5,17.1,5c0,0,0,0,0,0c3,0,5.4,2.4,5.4,5.4C22.5,12.3,21.3,13.7,20.1,15z"
      />
    </g>
  </svg>
)

export default Iheart
