import React from 'react'
import styled, { css } from 'styled-components'

const StyledButton = styled.button`
  padding: 8px 30px;
  border: none;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.color.disabled : theme.color.primary};
  color: ${({ theme }) => theme.color.white};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  box-shadow: ${({ theme }) => theme.shadow};
  transition: color 200ms, background-color 300ms, opacity 200ms;

  :hover {
    opacity: 0.8;
  }

  :focus,
  :active {
    outline: none;
  }

  ${({ variant }) =>
    variant === 'secondary' &&
    css`
      padding: 8px 10px 8px 12px;
      border-radius: 15px;
      background-color: ${({ theme }) => theme.color.accentSecondary}73;
      color: ${({ theme }) => theme.color.primary};
      box-shadow: 1px 4px 5px #0000000f;

      :hover {
        background-color: ${({ theme }) => theme.color.accentSecondary};
        color: ${({ theme }) => theme.color.white};
        opacity: 1;
      }
    `}

  ${({ variant }) =>
    variant === 'outline' &&
    css`
      border: 1px solid ${({ theme }) => theme.color.white};
      background-color: ${({ theme }) => theme.color.black};
      color: ${({ theme }) => theme.color.white};
      box-shadow: 1px 4px 5px #0000000f;
      justify-content: center;
    `}

  ${({ variant }) =>
    variant === 'share' &&
    css`
      padding: 5px 10px;
      border-radius: 20px;
      font-weight: normal;
      font-size: 14px;
      background-color: #e3e3ff;
      color: ${({ theme }) => theme.color.primary};
      box-shadow: none;
      justify-content: center;

      :hover {
        opacity: 1;
      }
    `}
`

const Button = ({ disabled, onClick, children, variant, ...props }) => (
  <StyledButton
    variant={variant}
    disabled={disabled}
    onClick={!disabled ? onClick : undefined}
    {...props}
  >
    {children}
  </StyledButton>
)

export default Button
